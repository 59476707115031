body {
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

h1 {
  color: white;
  font-size: var(--h1FontSize);
  font-family: var(--body-title-font-family);
  font-weight: var(--h1FontWeight);
  line-height: var(--line-height-l);
  overflow-wrap: break-word;
}

h2 {
  font-weight: var(--fontWeightM);
  font-family: var(--body-title-font-family);
}

nav {
  top: 0;
  left: 0;
}

.App {
  padding:0;
  margin:0;
}

body {
  margin: 0;
}

h1, h2 {
    color: var(--main-text-color);
    text-shadow: 0 0 5px rgba(255,255,255,0.5);
}

.main-body .block {
  display: flex;
  flex-direction: column;
}
