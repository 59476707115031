.home-hero.screendetail h2 {
  font-size: 1.25em;
  max-width: 800px;
  margin: unset;
}

.home-hero.screendetail picture>img {
  height: auto;
}

.main-body.screendetail {
  width: 100%;
  background-color: white;
}

.main-body.screendetail h2 {
  line-height: 24px;
  padding-top: 7px;
  font-size: large;
}

.main-body.main-body.screendetail>.inner-content {
  max-width: 1600px;
  margin: auto;
  width: 100%;
  padding-bottom: 50px;
}

.main-body.screendetail .adventure-details>ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style: none;
  margin: auto;
  padding: 0 26px;
}

.main-body.screendetail .tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;
  z-index: 40;
  position: relative;
  width: 100%;
  /* background-color: black; */
}

.main-body.screendetail .tab>.item {
  flex: 1;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.8s ease;
  padding: 16px;
  color: white;
  text-shadow: .75px .75px #888;
  transition: opacity .8s ease;
}

.main-body.screendetail .tab>.item>div {
  background: var(--main-color-opacity-lighter);
  padding: 10px;
  border-radius: 5px;
}

.main-body.screendetail h6{
  text-align: left;
}

.main-body.screendetail .tab>.item span {
  font-size: larger;
  border-bottom: 0.6px solid white;
  display: block;
  width: 100%;
}

.main-body.screendetail .tab > .item p {
  line-height: 24px;
  padding-top: 7px;
}

/* .main-body.screendetail .tab>.item:hover .inner-text {
  background: var(--main-color-opacity-darker);
  transition: flex 10s ease;
  display: unset;
} */

.main-body.screendetail h2 {
  color: white;
}

.main-body.screendetail .adventure-details>ul>li>p {
  padding-top: 0.5em;
  font-weight: 600;
  font-size: 14px;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.main-body.screendetail .adventure-details>ul>li {
  text-align: center;
}

/* .main-body.screendetail .tab>.item:hover {
  flex: 70;
  opacity: .1 !important;
} */

body {
  background-color: var(--main-color);
  background-image: unset;
}

.main-body {
  display: flex;
  flex-direction: column;
}

.main-body>.block {
  width: 100%;
  top: unset;
  left: unset;
  position: unset;

  background-color: white;
  margin: unset;
  padding: 32px 0;
}

.main-body>.block:nth-child(1) {
  padding-top: 64px;
  opacity: 1;
}

@media screen and (min-width:1200px) {
  .main-body.screendetail .tab {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width:600px) {
  .main-body.screendetail .adventure-details>ul {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .main-body.screendetail .tab div.adventure-details > ul h6{
    text-align: center;
  }
}