/*
Copyright 2023 Adobe
All Rights Reserved.
NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it.
*/
footer {
  width: 100%;
  background-color: #696969;
  z-index: 9;
}
.footer-xf, .version {
  width: 92%;
  /* display: flex; */
  flex-direction: column;
  padding: 30px 0;
  margin:auto;
}

.version {
  font-size: x-small;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-xf .aem-Grid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.footer-xf .image {
  width: 180px;
}

.footer-xf .navigation {
  display: none;
}

.footer-xf .title, .buildingblock {
  display: none;
}

.footer-xf .text > div {
  display: flex;
  flex-direction: column;
}



