/*
Copyright 2023 Adobe
All Rights Reserved.
NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it.
*/

header {
  position: relative;
}

header.home-article img {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
