body {
  background-color: var(--main-color);
  font-family: var(--body-font-family);
  margin: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-display: block;
  font-family: wknd-icon-font;
  font-style: normal;
  font-weight: 400;
  src: url(./media/fonts/wknd-icon-font.ttf) format("truetype"), 
    url(./media/fonts/wknd-icon-font.woff) format("woff"), 
    url(./media/fonts/wknd-icon-font.svg#wknd-icon-font) format("svg")
}

@font-face {
  font-family: "adobe-clean";
  src: url("https://use.typekit.net/af/c0160f/00000000000000007735dac8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/c0160f/00000000000000007735dac8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/c0160f/00000000000000007735dac8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "adobe-clean";
  src: url("https://use.typekit.net/af/95bf80/00000000000000007735dacd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/95bf80/00000000000000007735dacd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/95bf80/00000000000000007735dacd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "adobe-clean";
  src: url("https://use.typekit.net/af/5c07ba/00000000000000007735dad8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/5c07ba/00000000000000007735dad8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/5c07ba/00000000000000007735dad8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "adobe-clean";
  src: url("https://use.typekit.net/af/dc1cb5/00000000000000007735dadb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/dc1cb5/00000000000000007735dadb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/dc1cb5/00000000000000007735dadb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "adobe-clean";
  src: url("https://use.typekit.net/af/bc79c1/00000000000000007735dad9/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/bc79c1/00000000000000007735dad9/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/bc79c1/00000000000000007735dad9/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
}

@font-face {
  font-family: "adobe-clean";
  src: url("https://use.typekit.net/af/f5ecaa/00000000000000007735dad6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/f5ecaa/00000000000000007735dad6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/f5ecaa/00000000000000007735dad6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
}

@font-face {
  font-family: "adobe-clean-serif";
  src: url("https://use.typekit.net/af/15eaed/00000000000000007735dac6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/15eaed/00000000000000007735dac6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/15eaed/00000000000000007735dac6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "adobe-clean-serif";
  src: url("https://use.typekit.net/af/2dc334/00000000000000007735dac7/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/2dc334/00000000000000007735dac7/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/2dc334/00000000000000007735dac7/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
}

[class$=-icon],
[class$=__icon],
[class*=-icon],
[class*=__icon] {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: wknd-icon-font !important;
  font-size: 1.25rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: none
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--textColor, red);
  line-height: 1.5
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  font-family: var(--fontFamilySerif, "Asar", Georgia, "Times New Roman", Times, serif);
  font-weight: var(--fontWeightNormal, normal);
  margin-bottom: 13.5px;
  margin-top: 27px
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  font-family: var(--fontFamilySansSerif, "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif);
  font-weight: var(--fontWeightBold, 600);
  text-transform: uppercase
}

.h1,
.hero h1 {
  font-size: var(--fontSizeH1)
}

.h2,
h2 {
  font-size: var(--fontSizeH2)
}

.h3,
h3 {
  font-size: var(--fontSizeH3)
}

.h4,
h4 {
  font-size: var(--fontSizeH4)
}

.h5,
h5 {
  font-size: var(--fontSizeH5)
}

.h6,
h6 {
  color: var(--fontColorH6);
  font-size: var(--fontColorH6);
  padding-top: 1em;
  padding-bottom: unset;
  margin-bottom: unset;
}

a {
  color: var(--anchorColor);
  text-decoration: none
}

h1 a,
h2 a,
h3 a {
  color: var(--titleAnchorColor)
}

h1 a:after,
h2 a:after,
h3 a:after {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  font-family: wknd-icon-font !important;
  font-size: 1.25rem;
  font-size: var(--fontSizeXSmall);
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  left: .75em;
  line-height: 1;
  position: relative;
  text-transform: none;
  top: -.5em
}

p {
  font-size: var(--font-size-body);
  line-height: var(--line-height-body);
  margin: 0 0 13.5px;
  text-align: justify
}

div[role=status] {
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

:root {
  --main-accent-color:#ffc719;
  --hero-teaser-height:100vh;
  --hero-teaser-width: 100vw;
  --hero-teaser-positon: sticky;
  --featured-teaser-direction: row-reverse;
  --highlight-background-color: red;
  --main-text-color: rgba(41,41,41);
  --main-color:#fff;
  --main-color-opacity:rgba(4,25,45,.55);
  --main-color-opacity-lighter:rgba(4,25,45,.45);
  --main-color-opacity-darker:rgba(4,25,45,.95);
  --main-light-background: #EBEBEB;
  --font-family: "wknd-icon-font" !important;
  --main-nav-link-color: #fff;
  --main-nav-color: red;
  --dark-nav: rgba(41,41,41);
  --main-dark-background: rgba(41,41,41);
  --light-nav: #fff;
  --font-size-body: 18px;
  --line-height-body: 2.5;
  --fontSizeH1: 60px;
  --fontSizeH2: 28px;
  --fontSizeH3: 24px;
  --fontSizeH4: 21px;
  --fontSizeH5: 14px;
  --fontSizeH6: 12px;
  --fontSizeMed: 16px;
  --fontColorH6: #696969;
  --titleAnchorColor: red;
  --anchorColor: #0045ff;
  --fontSizeXSmall: var(--fontSizeH6);
  --navFontSize: 44px;
  --navFontWeight: 500;
  --fontWeightM: var(--navFontWeight);
  --h1FontWeight: 600;
  --h1FontSize: 72px;
  --line-height-l: 76px;
  --body-font-family: adobe-clean, arial, sans-serif;
  --body-title-font-family: var(--body-font-family);
}

.blue-box {
  border: 2px solid blue !important;
  background-color: #0045ff;
}
